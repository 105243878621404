.restaurants {
	padding: 30px 20px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	margin: 24px 0 8px;

	color: var(--darkBlue);

	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
}

.tabs {
	padding: 0 16px;

	border: 1px solid var(--gray);
	border-radius: 8px;
}

.addTabButton:disabled {
	margin-left: 8px;

	color: black;

	font-size: 14px;
	font-weight: 600;
}

.addTab {
	display: flex;
	align-items: center;
	justify-content: center;
}

.headerSelectRestAndBars :global .mantine-Select-defaultVariant {
	width: 450px;
	min-height: 29px;
	padding: 0 36px 0 0;

	color: var(--darkBlue);
	border: none;

	font-size: 18px;
	font-weight: 700;
	line-height: 160%;
}

:global .mantine-Tabs-body {
	padding-top: 0;
}
