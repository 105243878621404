.addMenuBarModal :global .mantine-Modal-modal {
  height: 472px;
  padding: 16px 24px;
}

.addMenuBarModal :global .mantine-Modal-title {
  color: var(--darkBlue);

  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
}

.addMenuBarModal :global .mantine-Modal-header {
  margin-bottom: 0;
  padding-bottom: 16px;
}

.addMenuBarModal :global .mantine-Modal-close svg {
  width: 24px;
  height: 24px;

  color: var(--secendary);
}

.addMenuBarModalGroup {
  margin-top: 16px;
}

.addMenuBarModalGroup:last-child {
  margin-top: 0;
  margin-bottom: 16px;
}

.addMenuBarModal :global .mantine-Modal-body {
  height: 336px;
  max-height: initial;
}

.addMenuBarModal :global .mantine-Modal-body::-webkit-scrollbar {
  width: 0;
}

.addMenuBarModalName {
  width: 73%;
}

.isBar {
  width: 100%;
}

.addMenuBarModalPrice {
  width: 22%;
}

.addMenuBarModalName :global .mantine-InputWrapper-label,
.addMenuBarModalPrice :global .mantine-InputWrapper-label {
  color: var(--violet-dark);

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.addMenuBarModalName :global .mantine-Input-input,
.addMenuBarModalPrice :global .mantine-Input-input {
  height: 40px;

  color: var(--darkBlue);
  border: 1px solid var(--areaWhite);
  border-radius: 7px;

  background: var(--lightWhite);
}

.addMenuBarModalBtnAdd {
  margin-bottom: 50px;
}

.addMenuBarModalFooter {
  position: absolute;

  width: calc(100% - 48px);
  height: 72px;

  background: var(--white);
}
