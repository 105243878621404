.wrapper {
	display: grid;
	grid-template-columns: 256px auto;
	grid-template-rows: auto 1fr;

	min-height: 100vh;
	grid-template-areas:
		"header header"
		"navbar body";
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 15px 16px;
	grid-area: header;
}

.navbar {
	grid-area: navbar;
}

.body {
	position: relative;

	padding: 36px 32px;
	grid-area: body;

	background: var(--lightGray);
	box-shadow: 22px 25px 37px -21px rgb(34 60 80 / 11%) inset;
}
