.cruiseControlNotification {
	display: grid;

	padding: 24px;
}

.notificationTableWrap {
	border: 1px solid var(--gray);
	border-radius: 8px;
}

.notification .notificationTHead {
	height: 56px;
}

.notification .notificationTHeadRow th {
	color: var(--darkBlue);

	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
}

.notification .notificationTHeadRow th:first-child {
	width: 180px;
}

.notification .notificationTHeadRow th:nth-child(2) {
	width: 230px;
}

.notification .notificationTHeadRow th:nth-child(3) {
	width: 448px;
}

.notification .notificationTHeadRow th:nth-child(4) {
	width: 156px;
}

.notification .notificationTHeadRow th:last-child {
	width: 50px;
}

.notificationTbody .notificationTbodyRow td {
	padding: 20px 16px;

	color: var(--darkBlue);
	border-bottom: none;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.notification .notificationTbody .notificationTbodyRow:hover {
	background-color: var(--lightBlue);
}

.notificationTbody .notificationTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.notificationTbody .notificationTbodyImg svg {
	transform: rotate(90deg);
}

.notificationMenuBtnAddWrap {
	position: relative;

	height: 56px;
	padding: 0 16px;
}

.notificationMenuBtnAddWrap::after {
	position: absolute;
	top: 0;
	left: 16px;

	display: block;

	width: calc(100% - 32px);
	height: 1px;

	content: "";

	background: var(--smokyGray);
}

/* Switch style */

.notificationSwitch :global .mantine-Switch-input {
	overflow: initial;

	width: 34px;
	min-width: 34px;
	height: 14px;

	border: 1px solid var(--darkSmokyGray);
	background-color: var(--darkSmokyGray);
}

.notificationSwitch :global .mantine-Switch-input:checked {
	border-color: var(--smokyBlue);
	background-color: var(--smokyBlue);
}

.notificationSwitch :global .mantine-Switch-input::before {
	width: 20px;
	height: 20px;

	transform: translateX(-2px);

	background-color: var(--secondWhite);
	box-shadow: 0 0 2px rgb(0 0 0 / 14%), 0 2px 2px rgb(0 0 0 / 12%), 0 1px 3px rgb(0 0 0 / 20%);
}

.notificationSwitch :global .mantine-Switch-input:checked::before {
	transform: translateX(16px);

	border-color: #00B9E7;
	background-color: #00B9E7;
	box-shadow: 0 0 2px rgb(0 0 0 / 14%), 0 2px 2px rgb(0 0 0 / 12%), 0 1px 3px rgb(0 0 0 / 20%);
}
