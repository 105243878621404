.listStub {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	min-height: 200px;

	background: var(--white);

	font-size: 20px;
	gap: 10px;
}
