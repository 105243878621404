.applicationList .applicationListTHead th {
	color: var(--darkBlue);
	border-bottom: 1px solid var(--smokyGray);

	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
}

.applicationList .applicationListTHead th:first-child {
	width: 80px;
}

.applicationList .applicationListTHead th:last-child {
	width: 50px;
}

.applicationList .applicationListTHead th:nth-child(6) {
	width: 140px;
}

.applicationList .applicationListTHead th:nth-child(7) {
	width: 134px;
}

.applicationListTbody .applicationListTbodyRow td {
	color: var(--darkBlue);
	border-bottom: none;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.applicationListTbody .applicationListTbodyRow td:last-child {
	width: 20px;
}

.applicationList .applicationListTbody .applicationListTbodyRow:hover {
	background-color: var(--lightBlue);
}

.applicationListTbody .applicationListTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.applicationListTbody .applicationListTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.applicationListTbody .applicationListTbodyImg svg {
	transform: rotate(90deg);
}

.modalCustomTitle :global .mantine-Modal-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.modalCustomTitle :global .mantine-Modal-header {
	margin-bottom: 32px;
}

.modalCustomTextarea :global .mantine-Textarea-label {
	color: #28293D;

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.modalCustomTextarea :global .mantine-Textarea-input {
	height: 158px;
	padding: 8px;

	border: 1px solid var(--areaWhite);
	border-radius: 7px;

	background: var(--lightWhite);
}
