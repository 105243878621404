.saunaEntryModal :global .mantine-Modal-modal {
	padding: 16px 24px;
}

.saunaEntryModal :global .mantine-Modal-header {
	margin-bottom: 28px;
}

.saunaEntryModalInput :global .mantine-InputWrapper-label {
	color: var(--darkBlue);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.saunaEntryModalInput :global .mantine-Input-input {
	color: var(--darkBlue);
	border: 1px solid var(--areaWhite);
	border-radius: 8px;
	background: var(--lightWhite);
}

.saunaEntryModalFooter {
	margin-top: 30px;
	padding-top: 16px;
}
