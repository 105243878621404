.scheme {
	display: flex;
	overflow: scroll;
	justify-content: center;

	width: 100%;

	max-height: 350px;
}


.fio {
	padding: 5px;

	cursor: pointer;
}

.fio:hover {
	background: var(--lightBlue);
}
