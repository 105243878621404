.drawerModal {
	overflow-y: scroll;

	padding: 0 16px 16px;
}

.drawerModal::-webkit-scrollbar {
	overflow: hidden;

	width: 0;
}

.drawerModal :global .mantine-Drawer-header {
	flex-direction: row-reverse;
	justify-content: flex-end;

	height: 46px;
	gap: 20px;

	margin-bottom: 24px;
}

.drawerModal :global .mantine-Drawer-closeButton svg {
	width: 24px;
	height: 24px;

	color: var(--secendary);
}

.drawerModalHeaderMenu {
	position: absolute;
	top: 10px;
	right: 16px;
}

.drawerModalHeaderMenu svg {
	width: 20px;
	height: 20px;

	transform: rotate(90deg);

	color: var(--secendary);
}

.drawerModal :global .mantine-Drawer-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.drawerModalImageWrapper {
	margin-bottom: 16px;
}

.drawerModalImageWrapper :global .mantine-Image-image {
	max-width: 388px;
	max-height: 264px;
}

.drawerModalTextCity {
	margin: 16px 0 8px;

	color: var(--darkBlue);

	font-size: 24px;
	font-weight: 700;
	line-height: 34px;
}

.drawerModalTextTime {
	color: var(--darkBlue);

	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

.drawerModalMainExcursionsWrap,
.drawerModalAddExcursionsWrap {
	margin-top: 20px;
}

.drawerModalMainExcursions,
.drawerModalAddExcursions {
	color: var(--darkBlue);

	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.drawerModalMainExcursionsTimeLift,
.drawerModalAddExcursionsTimeLift {
	max-width: 76px;
	height: 76px;

	color: var(--darkBlue);

	font-size: 14px;

	font-weight: 400;
	line-height: 20px;
}

.drawerModalExcursionsText {
	max-width: 288px;
}

.drawerModalExcursionsTitle {
	color: var(--darkBlue);

	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.drawerModalNotification {
	color: var(--green);

	font-family: "Open Sans", sans-serif;
	font-size: 12px;

	font-weight: 400;
	line-height: 16px;
}

.drawerModalAddNotification {
	color: var(--darkBlue);

	font-family: "Open Sans", sans-serif;
	font-size: 12px;

	font-weight: 400;
	line-height: 16px;
}
