.restaurantsListWrapFlightSchedule {
	display: grid;

	padding: 16px;
}

.flightSchedule {
	border: 1px solid var(--gray);
	border-radius: 8px;
}

.flightScheduleTbody .flightScheduleTbodyRow .flightScheduleTbodyState {
	padding: 12px 16px;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.flightScheduleTbody .flightScheduleTbodyRow td {
	padding: 20px 16px;

	color: var(--darkBlue);
	border-bottom: none;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.applicationList .flightScheduleTHead {
	height: 56px;
}

.applicationList .flightScheduleTHeadRow th {
	color: var(--darkBlue);

	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
}

.flightScheduleTbodyRow {
	cursor: pointer;
}

.applicationList .flightScheduleTHeadRow th:last-child {
	width: 50px;
}

.applicationList .flightScheduleTbody .flightScheduleTbodyRow:hover {
	background-color: var(--lightBlue);
}

.flightScheduleTbody .flightScheduleTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.flightScheduleTbody td:first-child {
	vertical-align: top;
}

.flightScheduleTbody .flightScheduleTbodyImg svg {
	transform: rotate(90deg);
}

.flightScheduleBtnWrap {
	position: relative;

	height: 56px;
	padding: 0 16px;
}

.flightScheduleBtnWrap::after {
	position: absolute;
	top: 0;
	left: 16px;

	display: block;

	width: calc(100% - 32px);
	height: 1px;

	content: "";

	background: var(--smokyGray);
}
