g[id*="Table_"] rect:first-child {
	fill: #EFEFEF;
}

.rectNew {
	width: 5px;
	height: 17px;

	border-radius: 2px;
	stroke: white;
	stroke-linejoin: round;
}
