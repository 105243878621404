.cruiseControlTabs :global .mantine-Tabs-tabControl {
	height: 48px;
	padding: 16px 26px;

	color: var(--shadowTabGray);

	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
}

.cruiseControlTabs :global .mantine-Tabs-tabControl.__mantine-ref-tabActive {
	color: var(--darkBlue);
	border-bottom-color: var(--secendary);
}
