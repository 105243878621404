.header {
	display: flex;
	justify-content: space-between;

	margin-bottom: 16px;
}

.headerTitle {
	color: var(--darkBlue);

	font-size: 20px;
	font-weight: 700;
	line-height: 40px;
}

.sectionRightWrap {
	flex-shrink: 2;
}

.sectionCalendarWrap {
	width: 100%;
}

/* таблица записи в сауну  */

.saunaTable {
	border-collapse: initial;
	border-radius: 4px;
	background-color: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.saunaTable .saunaTableTHead th {
	height: 56px;

	color: var(--darkBlue);
	border-bottom: 1px solid var(--smokyGray);

	font-size: 12px;

	font-weight: 700;
	line-height: 24px;
}

.saunaTable .saunaTableTHead th:first-child {
	width: 198px;
}

.saunaTable .saunaTableTHead th:last-child {
	width: 56px;
}

.saunaTableTbody {
	overflow: scroll;
}

.saunaTableTbody .saunaTableTbodyRow td {
	padding: 12px 16px;

	color: var(--darkBlue);
	border-bottom: none;

	font-size: 12px;

	font-weight: 400;
	line-height: 16px;
}

.saunaTableTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.saunaTableTbody .saunaTableTbodyRow:hover {
	background: var(--lightBlue);
}

.saunaTableTbody .saunaTableTbodyImg svg {
	transform: rotate(90deg);
}

/* календарь */

.programDayCalendar {
	overflow: hidden;
	justify-content: center;

	width: 100%;
	max-width: 348px;
	padding: 16px;

	border-radius: 8px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevelIcon {
	display: none;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevel {
	height: 28px;

	color: var(--darkBlue);

	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeader {
	margin-bottom: 10px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl {
	width: 28px;
	height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl svg {
	width: 24px;
	height: 24px;

	color: var(--darkBlue);
}

.programDayCalendar :global .mantine-Calendar-day {
	width: 40px;
	height: 36px;

	color: var(--darkBlue);

	line-height: 36px;
}

.programDayCalendar :global .__mantine-ref-selected:not(:disabled) {
	background-color: var(--darkBlue);
}

:global .mantine-Calendar-weekdayCell:nth-child(6) .mantine-Calendar-weekday,
:global .mantine-Calendar-weekdayCell:nth-child(7) .mantine-Calendar-weekday {
	color: var(--weekDayRed);
}

.paginationWrap {
	width: 100%;
	padding: 15px 12px;

	background: var(--white);
}

.buttonIcon > path {
	fill: white;
}