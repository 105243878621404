.addRestaurantModal :global .mantine-Modal-modal {
	padding: 16px 24px 72px;
}

.addRestaurantModal :global .mantine-Modal-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.addRestaurantModal :global .mantine-Modal-header {
	margin-bottom: 30px;
}

.addRestaurantModal :global .mantine-Modal-close svg {
	width: 24px;
	height: 24px;

	color: var(--secendary);
}

.addRestaurantModalSwitch :global .mantine-Switch-input {
	overflow: initial;

	width: 34px;
	min-width: 34px;
	height: 14px;

	border: 1px solid var(--darkSmokyGray);
	background-color: var(--darkSmokyGray);
}

.addRestaurantModalSwitch :global .mantine-Switch-input:checked {
	border-color: var(--smokyBlue);
	background-color: var(--smokyBlue);
}

.addRestaurantModalSwitch :global .mantine-Switch-input::before {
	width: 20px;
	height: 20px;

	transform: translateX(-2px);

	background-color: var(--secondWhite);
	box-shadow: 0 0 2px rgb(0 0 0 / 14%), 0 2px 2px rgb(0 0 0 / 12%), 0 1px 3px rgb(0 0 0 / 20%);
}

.addRestaurantModalSwitch :global .mantine-Switch-input:checked::before {
	transform: translateX(16px);

	border-color: #00B9E7;
	background-color: #00B9E7;
	box-shadow: 0 0 2px rgb(0 0 0 / 14%), 0 2px 2px rgb(0 0 0 / 12%), 0 1px 3px rgb(0 0 0 / 20%);
}

.addRestaurantModalSwitch :global .mantine-Switch-label {
	color: var(--darkBlue);

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.addRestaurantModalContent {
	margin-top: 30px;
	margin-bottom: 60px;
}

.removeMB {
	margin-bottom: 8px;
}

.addRestaurantModalInput :global .mantine-InputWrapper-label,
.addRestaurantModalSelect :global .mantine-Select-label,
.addRestaurantModalTextArea :global .mantine-Textarea-label {
	color: var(--violet-dark);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.addRestaurantModalInput :global .mantine-Input-input,
.addRestaurantModalSelect :global .mantine-Select-input {
	height: 40px;

	color: var(--darkBlue);
	border: 1px solid var(--areaWhite);
	border-radius: 7px;

	background: var(--lightWhite);
}

.addRestaurantModalTextArea :global .mantine-Textarea-input {
	padding: 8px;

	color: var(--darkBlue);
	border: 1px solid var(--areaWhite);
	border-radius: 8px;
	background: var(--lightWhite);

	font-size: 14px;

	font-weight: 400;
	line-height: 20px;
}

.addRestaurantModalFooter {
	position: fixed;

	width: calc(100% - 48px);
	height: 72px;

	background: var(--white);
}

.checkboxAgreed {
	display: flex;
	line-height: 20px;
}
.checkboxLabel {
	padding-left: 12px;
}
.checkboxAsterisk {
	color: red;
}