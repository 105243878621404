.workingHours {
	position: relative;

	width: 348px;
	height: 258px;
	padding: 16px;

	border-radius: 8px;

	background: var(--white);

	overflow-y: scroll;
}

.workingHoursHeader {
	margin-bottom: 32px;
}

.workingHoursTitle {
	color: var(--darkBlue);

	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
}

.workingHoursWrapItem {
	display: flex;
	flex-wrap: wrap;
	gap: 24px 20px;
}

.workingHoursItem {
	padding: 8px 6px;

	cursor: pointer;

	border-radius: 6px;

	background: var(--lightGray);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);

	font-size: 12px;

	font-weight: 400;
	line-height: 16px;
}

.isActive {
	color: var(--white);
	background: var(--secendary);
}

.isReserved {
	color: var(--white);
	background: var(--shadowTabGray);
}
