.cruiseRestaurant .cruiseRestaurantTHead {
	height: 48px;
}

.cruiseRestaurant .cruiseRestaurantTHeadRow th {
	color: var(--darkBlue);

	border-bottom: 1px solid var(--smokyGray);

	font-size: 12px;
	font-weight: 700;
	line-height: 24px;
}

.cruiseRestaurant .cruiseRestaurantTHeadRow th:last-child {
	width: 50px;
}

.cruiseRestaurantTbody .cruiseRestaurantTbodyRow td {
	padding: 20px 16px;

	color: var(--darkBlue);
	border-bottom: none;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.cruiseRestaurant .cruiseRestaurantTbody .cruiseRestaurantTbodyRow:hover {
	background-color: var(--lightBlue);
}

.cruiseRestaurantTbody .cruiseRestaurantTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.cruiseRestaurantTbody .cruiseRestaurantTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.cruiseRestaurantTbody .cruiseRestaurantTbodyImg svg {
	transform: rotate(90deg);

	color: var(--darkBlue);
}

.cruiseRestaurantMenuBtnAddWrap {
	height: 56px;
}
