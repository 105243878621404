.registrationList .registrationListTHead th {
	color: var(--darkBlue);
	border-bottom: 1px solid var(--smokyGray);

	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
}

.applicationList .applicationListTHead th:last-child {
	width: 0;
}

.registrationListTbody .registrationListTbodyRow td {
	color: var(--darkBlue);
	border-bottom: none;
}

.registrationList .registrationListTbody .registrationListTbodyRow:hover {
	background-color: var(--lightBlue);
}

.registrationListTbody .registrationListTbodyRow:nth-child(2n+1) {
	background: var(--smokyWhite);
}

.registrationListTbody .registrationListTbodyImg svg {
	transform: rotate(90deg);
}
