.changeTableModal :global .mantine-Modal-modal {
	padding: 24px 24px 72px;
}

.changeTableModalFooter {
	position: fixed;

	width: calc(100% - 36px);
	height: 72px;

	background: white;
}
