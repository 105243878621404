.navbarContent {
	display: flex;
	flex-direction: column;

	margin-top: 16px;
}

.navbarItem {
	display: flex;
	align-items: center;

	padding: 16px;

	line-height: 0;
}

.navbarItem svg {
	margin-right: 12px;
}

.navbarItem:hover {
	background: #F5F8FB;
}

.navbarItem:hover .navbarItemText {
	color: var(--secendary);
}

.navbarItem:hover svg {
	fill: var(--secendary);
}

.navbarItemText {
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
}

.navbarItemNotification {
	margin-left: auto;
	padding: 4px 8px;

	color: #FFF;
	border-radius: 16px;
	background: #EE7605;

	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
}
