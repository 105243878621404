.flightScheduleModal :global .mantine-Modal-modal {
  max-height: calc(100vh - 96px);
  padding: 24px 24px 72px;
}

.flightScheduleModal :global .mantine-Modal-title {
  color: var(--darkBlue);

  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
}

.flightScheduleModal :global .mantine-Modal-header {
  margin-bottom: 40px;
}

.flightScheduleModal :global .mantine-Modal-close svg {
  width: 24px;
  height: 24px;

  color: var(--secendary);
}

.flightScheduleModalSelectState {
  width: 298px;
}

.flightScheduleModalSelectDate {
  width: 180px;
}

.flightScheduleModalSelectTime {
  width: 180px;
}

.flightScheduleModalSelectState :global .mantine-Select-label,
.flightScheduleModalSelectDate :global .mantine-DatePicker-label,
.flightScheduleModalSelectTime :global .mantine-InputWrapper-label,
.flightScheduleModalExcursionDay :global .mantine-InputWrapper-label,
.flightScheduleModalExcursionTime :global .mantine-InputWrapper-label,
.flightScheduleModalExcursionTextarea :global .mantine-Textarea-label,
.flightScheduleModalExcursionPrice :global .mantine-InputWrapper-label {
  color: var(--violet-dark);

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.flightScheduleModalSelectState :global .mantine-Select-input,
.flightScheduleModalSelectDate :global .mantine-DatePicker-input,
.flightScheduleModalSelectTime :global .mantine-Input-input {
  border: 1px solid var(--areaWhite);
  border-radius: 8px;
  background: var(--lightWhite);
}

.flightScheduleModalTitle {
  margin: 32px 0 16px;

  color: var(--darkBlue);

  font-size: 18px;

  font-weight: 700;
  line-height: 28px;
}

.flightScheduleModalExcursion {
  width: 100%;
  padding: 16px;
  gap: 24px;

  border-radius: 8px;
  background: var(--areaWhite);
}

.flightScheduleModalExcursion + .flightScheduleModalExcursion {
  margin-top: 32px;
}

.flightScheduleModalExcursionWrap > .flightScheduleModalExcursion:last-child {
  margin-bottom: 16px;
}

.flightScheduleModalBtnAddWrap {
  margin-bottom: 32px;
}

.flightScheduleModalExcursionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  color: var(--darkBlue);

  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.flightScheduleModalExcursionDay {
  width: 220px;
}

.flightScheduleModalExcursionTime {
  width: 400px;
}

.flightScheduleModalExcursionPrice {
  width: 312px;
}

.flightScheduleModalExcursionDay :global .mantine-Input-input,
.flightScheduleModalExcursionTime :global .mantine-Input-input,
.flightScheduleModalExcursionPrice :global .mantine-Input-input {
  border: 1px solid var(--areaWhite);
  border-radius: 8px;
  background: var(--white);
}

.flightScheduleModalExcursionTextarea {
  width: 100%;
}

.flightScheduleModalExcursionTextarea :global .mantine-Textarea-input {
  height: 100px;

  border: 1px solid var(--areaWhite);
}

.flightScheduleModal :global .mantine-Modal-body {
  max-height: calc(100vh - 267px);
}

.flightScheduleModal :global .mantine-Modal-body::-webkit-scrollbar {
  width: 0;
}

.flightScheduleModalFooter {
  position: absolute;

  width: calc(100% - 48px);
  height: 72px;

  background: white;
}
