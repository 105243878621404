.drawerRegistration {
	display: flex;
	overflow-y: scroll;
	flex-direction: column;
	justify-content: space-between;

	padding: 0 16px 16px;
}

.drawerRegistration::-webkit-scrollbar {
	overflow: hidden;

	width: 0;
}

.drawerRegistration :global .mantine-Drawer-header {
	flex-direction: row-reverse;
	justify-content: flex-end;

	height: 50px;
	gap: 20px;

	margin-bottom: 20px;
}

.drawerRegistration :global .mantine-Drawer-closeButton svg {
	width: 24px;
	height: 24px;

	color: var(--secendary);
}

.drawerRegistration :global .mantine-Drawer-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.drawerRegistrationEditTable :global .mantine-Select-label {
	margin-bottom: 0;

	color: var(--violet-dark);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.drawerRegistrationEditTable :global .mantine-Select-input {
	height: 40px;

	border: 1px solid var(--areaWhite);
	border-radius: 8px;
	background: var(--lightWhite);
}

.drawerRegistrationEditTable {
	width: 100%;
}

.scheme {
	overflow: scroll;

	width: 390px;
	height: 500px;
}

.scheme svg {
	width: 390px;
	height: auto;
}
