.container {
	display: grid;
	place-items: center;

	max-width: 590px;
}

.addTablesModal :global .mantine-Modal-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.addTablesModalFooter {
	width: 100%;
	margin: 10px;

	background: white;
}

.schemes {
	display: grid;
	overflow: scroll;

	width: 100%;

	max-height: 350px;
	place-items: center;
}

.schemes svg {
	width: 100%;
	height: auto;
}
