.editMenuBarModal :global .mantine-Modal-modal {
	padding: 16px 24px;
}

.editMenuBarModal :global .mantine-Modal-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.editMenuBarModal :global .mantine-Modal-header {
	margin-bottom: 32px;
}

.editMenuBarModal :global .mantine-Modal-close svg {
	width: 24px;
	height: 24px;

	color: var(--secendary);
}

.editMenuBarModalName {
	width: 67%;
}

.editMenuBarModalPrice {
	width: 30%;
}

.editMenuBarModalName :global .mantine-InputWrapper-label,
.editMenuBarModalPrice :global .mantine-InputWrapper-label {
	color: var(--violet-dark);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.editMenuBarModalName :global .mantine-Input-input,
.editMenuBarModalPrice :global .mantine-Input-input {
	height: 40px;

	color: var(--darkBlue);
	border: 1px solid var(--areaWhite);
	border-radius: 7px;

	background: var(--lightWhite);
}

.editMenuBarModalFooter {
	width: 100%;
	padding-top: 16px;

	background: var(--white);
}
