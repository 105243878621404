.applicationFilter {
	display: flex;
	flex-direction: column;

	height: 168px;
	padding: 24px;

	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.applicationFilterGroup {
	display: flex;

	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;

	justify-content: space-between;

	margin-bottom: 20px;
	gap: 32px;
}

.applicationFilterDatePickerWrap {
	max-width: 330px;
}

.applicationFilterDatePickerStart :global .mantine-DatePicker-label,
.applicationFilterDatePickerEnd :global .mantine-DatePicker-label,
.applicationFilterSelectTypeAppeal :global .mantine-Select-label {
	margin-bottom: 0;

	color: var(--violet-dark);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.applicationFilterDatePickerStart :global .mantine-DatePicker-input,
.applicationFilterDatePickerEnd :global .mantine-DatePicker-input,
.applicationFilterSelectTypeAppeal :global .mantine-Select-input {
	height: 40px;

	border: 1px solid var(--areaWhite);
	border-radius: 8px;
	background: var(--lightWhite);
}

.applicationFilterSelectTypeAppeal {
	max-width: 372px;
}

.applicationFilterBtnWrap {
	width: 328px;
	margin-left: auto;
}
