.drawerModal {
	overflow-y: scroll;

	padding: 0 16px;
}

.drawerModal::-webkit-scrollbar {
	width: 0;
}

.drawerModal :global .mantine-Drawer-header {
	flex-direction: row-reverse;
	justify-content: flex-end;

	height: 50px;
	gap: 20px;

	margin-bottom: 32px;
}

.drawerModal :global .mantine-Drawer-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

.drawerInputStatus :global .mantine-Select-wrapper {
	width: 128px;
	margin-bottom: 16px;
}

.drawerInputStatus :global .mantine-Select-label {
	color: var(--shadowGray);

	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

.drawerInputStatus :global .mantine-Select-input {
	height: 24px;
	min-height: 24px;
	padding: 4px 12px;

	text-align: left;

	color: var(--darkBlue);

	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

.drawerLabel {
	margin-bottom: 2px;

	color: var(--shadowGray);

	font-size: 16px;
	font-weight: 400;
	line-height: 160%;
}

.drawerGroup {
	gap: 2px;

	margin-bottom: 16px;
}

.drawerGroup:last-child {
	gap: 16px;

	margin-top: auto;
	margin-bottom: 0;
}

.drawerFooter {
	width: 100%;
	height: 72px;
	padding: 16px;

	background: white;
}
