:global .mantine-Modal-title {
	color: var(--darkBlue);

	font-size: 22px;
	font-weight: 700;
	line-height: 36px;
}

:global .mantine-Modal-close svg {
	width: 24px;
	height: 24px;

	color: var(--secendary);
}
