.container {
	display: grid;
	gap: 20px;

	width: 100%;
}

.schemes {
	position: relative;
	display: flex;
	width: 100%;
}

.schemes svg:last-child {
	width: 100%;
}
