.container {
	height: 100vh;

	background: var(--lightGray);
}

.content {
	position: relative;

	width: 492px;
	height: auto;
	padding: 24px;

	border-radius: 8px;
	background: var(--white);
}

.title {
	font-size: 22px;
	font-weight: 700;
	line-height: 160%;
}

.form {
	width: 100%;
}

.button {
	width: 100%;
	margin-top: 24px;
}
