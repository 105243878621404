.header {
	display: flex;
	justify-content: space-between;

	margin-bottom: 16px;
}

.headerTitle {
	color: var(--darkBlue);

	font-size: 20px;
	font-weight: 700;
	line-height: 40px;
}

.notificationBtnWrap {
	margin-bottom: 16px;
}

.paginationWrap {
	width: 100%;
	padding: 15px 12px;

	background: var(--white);
}

.application {
	background-color: white;
	width: 40%;
	padding: 20px;
}
