.programDay {
	padding: 16px;
}

.programDayContent {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

/* .programDayCalendarWrap { */

/*	padding: 16px; */

/*	max-width: 328px; */

/*	border: 1px solid var(--areaWhite); */

/*	border-radius: 8px; */

/* } */

.programDayTextDownload {
	color: var(--textBlack);

	line-height: 24px;
}

.programDayTextLink {
	width: 230px;

	color: var(--secendary);

	line-height: 24px;
}

/* календарь */

.programDayCalendar {
	overflow: hidden;
	justify-content: center;

	width: 100%;
	max-width: 328px;
	padding: 16px;

	border-radius: 8px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevelIcon {
	display: none;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderLevel {
	height: 28px;

	color: var(--darkBlue);

	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeader {
	margin-bottom: 10px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl {
	width: 28px;
	height: 28px;
}

.programDayCalendar :global .mantine-Calendar-calendarHeaderControl svg {
	width: 24px;
	height: 24px;

	color: var(--darkBlue);
}

.programDayCalendar :global .mantine-Calendar-day {
	width: 40px;
	height: 36px;

	color: var(--darkBlue);

	line-height: 36px;
}

.programDayCalendar :global .__mantine-ref-selected:not(:disabled) {
	background-color: var(--darkBlue);
}

:global .mantine-Calendar-weekdayCell:nth-child(6) .mantine-Calendar-weekday,
:global .mantine-Calendar-weekdayCell:nth-child(7) .mantine-Calendar-weekday {
	color: var(--weekDayRed);
}
