.registrationFilter {
	display: flex;
	flex-direction: column;

	height: 168px;

	background: var(--white);
	box-shadow: 0 5px 15px rgb(102 102 102 / 16%);
}

.registrationFilterGroup {
	display: flex;

	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;

	justify-content: space-between;

	margin-bottom: 20px;
	gap: 32px;
}

.registrationFilterSelectRest :global .mantine-Select-label,
.registrationFilterSelectTable :global .mantine-Select-label,
.registrationFilterSelectStatus :global .mantine-Select-label {
	margin-bottom: 0;

	color: var(--violet-dark);

	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}

.registrationFilterSelectRest :global .mantine-Select-input,
.registrationFilterSelectTable :global .mantine-Select-input,
.registrationFilterSelectStatus :global .mantine-Select-input {
	height: 40px;

	border: 1px solid var(--areaWhite);
	border-radius: 8px;
	background: var(--lightWhite);
}
