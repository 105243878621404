* {
	margin: 0;
	padding: 0;

	border: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

html {
	font-size: 14px;
}

body {
	line-height: 1;
}

input,
button,
textarea,
label {
	font-family: "Open Sans", sans-serif;
	font-size: inherit;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;

	background-color: inherit;
}

button::-moz-focus-inner {
	padding: 0;

	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

:root {
	--gray: #F0F0F3;
	--dark: #343434;
	--black: #000;
	--textBlack: #2B2B2B;
	--shadowGray: #82909C;
	--shadowTabGray: #B6CBDE;
	--secendary: #00B9E7;
	--smokyBlue: #80DDF4;
	--smokyGray: #E0E0E0;
	--darkSmokyGray: #9E9E9E;
	--smokyWhite: #FBFCFF;
	--secondWhite: #FAFAFA;
	--lightBlue: #E6F8FD;
	--lightGray: #F0F0F3;
	--white: #FFF;
	--lightWhite: #FAFAFC;
	--areaWhite: #EBEBF0;
	--darkBlue: #052039;
	--purpleBlue: #052039;
	--violet-dark: #28293D;
	--orange: #EE7605;
	--green: #06C602;
	--weekDayRed: #FF7A7A;
}
