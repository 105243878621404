.notification {
	display: inline-block;

	margin-right: 16px;
	padding: 4px 8px;

	color: var(--darkBlue);
	border-radius: 16px;
	background: var(--white);
	box-shadow: 0 5px 10px rgb(171 171 171 / 10%);

	font-size: 10px;

	font-weight: 400;
	line-height: 14px;
}

.notificationText {
	color: var(--darkBlue);

	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
}

.notificationCount {
	margin-left: 8px;
	padding: 2px 4px;

	border-radius: 16px;

	background: #EBEBF0;

	font-size: 10px;
	line-height: 14px;
}
