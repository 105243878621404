.headerUser {
	position: relative;
}

.dropdown {
	position: absolute;
	z-index: 10;
	margin-top: 5px;
	box-shadow: 0px 5px 15px rgba(102, 102, 102, 0.16)
}

.shipName {
	text-align: center;
	font-weight: 600;
	font-size: 15px;
	color: #052039;
}